import { Option} from './option';
import { QuestionList } from './option'; 

export class Question {
    // id: number;
    // name: string;
    QuestionTypeId: number;
    QuestionType:string;
    //options: Option[]; has changed to quesitonlist
    QuestionList: QuestionList[]
    //answered: boolean;

    constructor(data: any) {
        data = data || {};
        this.QuestionTypeId = data.QuestionTypeId;
        // this.name = data.name;
        this.QuestionType = data.QuestionType;
        this.QuestionList = [];
        data.QuestionList.forEach(o => {
            this.QuestionList.push(new QuestionList(o));
        });
    }
}