import { QuizConfig } from './quiz-config';
import { Question } from './question';

export class Quiz {
    // id: number;
    // name: string;
    // description: string;
    QuestionnaireId:number;
    QuestionnaireGroupId:number;
    QuestionnaireGroup:string;
    config: QuizConfig;
    QuestionTypeList: Question[];

    constructor(data: any) {
        if (data) {
            this.QuestionnaireId = data.QuestionnaireId;
            this.QuestionnaireGroupId=data.QuestionnaireGroupId;
            this.QuestionnaireGroup=data.QuestionnaireGroup;
            // this.name = data.name;
            // this.description = data.description;
            this.config = new QuizConfig(data.config);
            this.QuestionTypeList = [];
            data.QuestionTypeList.forEach(q => {
                this.QuestionTypeList.push(new Question(q));
            });
        }
    }
}
export class CompleteQuiz
{
    Quiz: Quiz[];
    constructor(data: any) {
        if (data) {
            this.Quiz = [];
            data.forEach(element => {
                this.Quiz.push(new Quiz(element))
            });
        }
    } 
}
