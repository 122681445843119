import { Component, OnInit, ViewChild } from '@angular/core';

import { QuizService } from '../services/quiz.service';
import { HelperService } from '../services/helper.service';
import { Question, Quiz, QuizConfig, QuestionList, CompleteQuiz } from '../models/index';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { IfStmt } from '@angular/compiler';
import swal from 'sweetalert2';
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css'],
  providers: [QuizService]
})
export class QuizComponent implements OnInit {
  @ViewChild('f') f: any
  quizes: any[];
  completeQuiz: CompleteQuiz = new CompleteQuiz(null);
  quiz: Quiz = new Quiz(null);
  mode: string = 'quiz';
  others: any;
  selectrole: any;
  radioSelected7: any;
  radioSelected8: any;
  radioSelected9: any;
  radioSelected10: any;
  radioSelected11: any;
  OtherSelect: boolean = false;
  quizName: string;
  SkillRating1: any;
  SkillRating2: any;
  SkillRating3: any;
  SkillRating4: any;
  SkillRating5: any;
  RoleRating: any;
  skill1: string;
  skill2: string;
  skill3: string;
  skill4: string;
  skill5: string;
  role: any;
  role1: string;
  role2: string;
  role3: string;
  role4: string;
  questionaryID:any
  referenceVal:any
  config: QuizConfig = {
    'allowBack': false,
    'allowReview': true,
    'autoMove': false,  // if true, it will move to next question automatically when answered.
    'duration': 0,  // indicates the time in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': false,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': false,
    'shuffleOptions': false,
    'showClock': false,
    'showPager': true,
    'theme': 'none'
  };
  show: boolean = false;
  email: string;
  name: any;
  checkRelation: any;
  showres: boolean = false;
  response = new SaveReferenceApproval();
  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  starsCount: number;
  starsCounts: number[] = [];
  // showQstns:boolean=false;
  constructor(public toastr: ToastrManager, private quizService: QuizService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      console.log(params);
      if (params['refId'] != null) {
        sessionStorage.setItem('refId', params['refId']);
        localStorage.setItem('stateOfUser', 'abcde');
      }
    });
    // this.quizes = this.quizService.getAll();
    // this.quizName = this.quizes[3].id;
    this.quizName = sessionStorage.getItem('refId');//'F3C4F5'; //'1BE3D2';'7AC0E0';
    this.loadQuiz(this.quizName);
  }

  loadQuiz(uniqueCode: string) {
    this.quizService.GetService(uniqueCode).subscribe(res => {
      let CheckId = res[0].QuestionnaireId;
      this.questionaryID = res[0].QuestionnaireId;
      let Pid = res[0].ProfileId;
      let skills = res[0].QuestionTypeList[0].QuestionList[0].Question.split(',');
      this.skill1 = skills[0];
      this.skill2 = skills[1];
      this.skill3 = skills[2];
      this.skill4 = skills[3];
      this.skill5 = skills[4];
      if (res[0].length > 0) {
        let roles = res[1].QuestionTypeList[0].QuestionList[0].Question.split(',');
        this.role1 = roles[0];
        this.role2 = roles[1];
        this.role3 = roles[2];
        this.role4 = roles[3];
      }

      else {
        this.role1 = 'Others';
      }

      if (CheckId == 1) {
        this.checkRelation = true;
      }
      else {
        this.checkRelation = false;
      }
      this.quizService.GetProfile(Pid).subscribe(res2 => {
        this.name = res2;
      })

      //this.quiz = new Quiz(res);
      //this.showres = true;
      this.completeQuiz = new CompleteQuiz(res);
      //this.pager.count = this.quiz.QuestionTypeList.length;
      this.pager.count = this.completeQuiz.Quiz.length;
      this.show = this.pager.count > 1 ? false : true;
    });
  }

  changeRoute() {
    window.location.href = 'https://arytic.com/';
  }

  onChange(val) {
    // this.relationStored()

    if (val == 5) {
      this.toastr.successToastr('Thank you for Information', 'Ohh!');
      //this.submitRef = new InsertReferences();
      sessionStorage.removeItem('refId');
      window.location.href = 'https://arytic.com/';
    }
    else {
      this.showres = true;
    }
  }



  changeModel(newName: string) {
    this.others = newName;
    this.role = this.others;
  }

  Select1(val) {
    if (val == 1) {

      if (this.role1 != "Others") {
        this.OtherSelect = false;
        this.role = this.role1;
      }
      else {
        this.OtherSelect = true;

      }

    }
    else if (val == 2) {

      if (this.role2 != "Others") {
        this.OtherSelect = false;
        this.role = this.role2;
      }
      else {
        this.OtherSelect = true;

      }
    }
    else if (val == 3) {
      if (this.role3 != "Others") {
        this.OtherSelect = false;
        this.role = this.role3;
      }
      else {
        this.OtherSelect = true;

      }


    }

    else {
      this.OtherSelect = true;

    }
  }


  // get filteredQuestions() {
  //   return (this.quiz.QuestionTypeList) ?
  //     this.quiz.QuestionTypeList.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  // }
  get filteredQuestions() {
    return (this.completeQuiz.Quiz) ?
      this.completeQuiz.Quiz.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }
  // onNext(val)
  // {
  // this.showQstns=true;
  // }
  // onSave(val)
  // {
  //   this.email=val;
  // }
  onSelect(question: Question, option: QuestionList, ans: any, response?: string) {
    console.log("ddddddd",question,option,ans,response)
    //  if (question.QuestionTypeId === 1) {
    //let response=new SaveReferenceApproval();
    // this.response.QuestionnaireId= this.quiz.QuestionnaireId;
    // this.response.uniqueCode=sessionStorage.getItem("refId");
    question.QuestionList.forEach(
      (x) => {
        // let qResponses=  new QuestionnaireResponses();
        if (x.QuestionId === option.QuestionId) {
          if (response === undefined) {
            x.Answer = ans;
          }
          x.ResponseValue = response;
          if(question.QuestionTypeId == 8 && option.QuestionId == 123){

          }
    console.log("ddddddd",question,option,ans,response )
    console.log("ddddddddddddd",question,option,ans,response,x.ResponseValue )

          //   qResponses.QuestionId=option.QuestionId;
          //   if(question.QuestionTypeId === 9 || 8){
          //   qResponses.ResponseId=parseInt(option.Answer,10);
          //   qResponses.ResponseValue='';
          //   }else{
          //     qResponses.ResponseValue= option.Answer;
          //     qResponses.ResponseId=null;
          //   }
          // this.response.ListCandidateReferenceDetails.push(qResponses)
        }
      }

    );
    // }

    if (this.config.autoMove) {
      this.goTo(this.pager.index + 1);
    }
  }
  // changeAnswer(value: string, id: number) {
  //         for (let i in this.questionList) {
  //             if (this.questionList[i].Id === id) {
  //                 this.questionList[i].ModifiedAnswers = value;
  //                 break;
  //             }
  //         }
  //     }
  goTo(index: number) {
    //if(this.f.valid){
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = 'quiz';
      this.show = false;
      //if(){
      this.config.allowBack = index === 0 ? false : true
      //}
    }
    if (index > 0 && index === this.pager.count - 1) {
      this.show = true;
      //this.config.allowBack=false;
    }
    //}

  }
  // surveyComplete()
  // {
  //    let response=new SaveReferenceApproval();
  //   this.response.QuestionnaireId= this.quiz.QuestionnaireId;
  //   this.response.uniqueCode= '7AC0E0';//sessionStorage.getItem("refId");
  //   this.quiz.QuestionTypeList.forEach(
  //     (x) => {

  //       x.QuestionList.forEach((y) => {
  //         let qResponses=  new QuestionnaireResponses();
  //       //if (y.QuestionId === option.QuestionId) {
  //          // y.Answer = option.Answer;
  //           qResponses.QuestionId=y.QuestionId;
  //           if(x.QuestionTypeId === 9 || x.QuestionTypeId === 8){
  //           qResponses.ResponseId= y.Answer.length > 0 ? parseInt(y.Answer,10) : null;
  //           qResponses.ResponseValue=null;
  //           }else{
  //             qResponses.ResponseValue= y.Answer;
  //             qResponses.ResponseId=null;
  //           }
  //         this.response.ListCandidateReferenceDetails.push(qResponses)
  //       //}
  //     });
  //   });
  //   this.quizService.PostService(this.response)
  //           .subscribe(data => {
  //               alert(data);
  //               //this.submitRef = new InsertReferences();
  //               sessionStorage.removeItem('refId');
  //               // if (this.loggedinUser != null) {
  //               //     this.router.navigateByUrl('dashboard');
  //               // } else {
  //               //     this.router.navigateByUrl('login');
  //               // }
  //           });


  // }
  relationStored()
  {
    const data = {
      uniqueCode:this.quizName,
    QuestionnaireId: this.questionaryID,
    relation:this.referenceVal
    }
    console.log("dataaaa",data);
    this.quizService.postRelation(data).subscribe((v:any) => {
      // window.location.href = 'https://www.arytic.com';
      
      console.log("post Relation",v)
      swal({
        title: "You have Completed Successfully",
        // text: "Once deleted, you will not be able to recover this imaginary file!",
        type: 'success',
        showConfirmButton: true,
         confirmButtonText: 'Explore Arytic'
        // showCancelButton: true     
      })
      .then((willDelete) => {
  
          if(willDelete.value){
                    window.location.href = 'https://www.arytic.com';
          }
  
        console.log(willDelete)
      });
    

    })
  }

  surveyComplete() {
    let response = new SaveReferenceApproval();
    this.response.ListCandidateReferenceDetails = [];
    this.completeQuiz.Quiz.forEach((z) => {
      this.response.QuestionnaireId = z.QuestionnaireId;
      this.response.uniqueCode = sessionStorage.getItem("refId");
      z.QuestionTypeList.forEach(
        (x) => {
          let answers = [];
          // x.QuestionList.forEach(x => answers.push({ 'QuizId': this.quiz.QuestionnaireId, 'QuestionId': x.QuestionId, 'Answered': x.Answers }));
          // debugger
          x.QuestionList.forEach((y) => {
            let qResponses = new QuestionnaireResponses();
            //if (y.QuestionId === option.QuestionId) {
            // y.Answer = option.Answer;
            qResponses.QuestionId = y.QuestionId;
            if (x.QuestionTypeId === 9 || x.QuestionTypeId === 8) {
              qResponses.ResponseId = y.Answer != null ? parseInt(y.Answer, 10) : null;
              qResponses.ResponseValue = qResponses.ResponseId + "";
            }
            else if (x.QuestionTypeId == 18) {
              qResponses.ResponseId = parseInt(y.Answer, 10);
              qResponses.ResponseValue = this.others;
            }
            else if (x.QuestionTypeId == 6 && y.QuestionId == this.radioSelected7) {
              qResponses.ResponseId = parseInt(y.Answers, 10);
              qResponses.ResponseValue = y.Question;
            }
            else if (x.QuestionTypeId == 6 && y.QuestionId == this.radioSelected8) {
              qResponses.ResponseId = parseInt(y.Answers, 10);
              qResponses.ResponseValue = y.Question;
            }
            else if (x.QuestionTypeId == 6 && y.QuestionId == this.radioSelected9) {
              qResponses.ResponseId = parseInt(y.Answers, 10);
              qResponses.ResponseValue = y.Question;
            }
            else if (x.QuestionTypeId == 6 && y.QuestionId == this.radioSelected10) {
              qResponses.ResponseId = parseInt(y.Answers, 10);
              qResponses.ResponseValue = y.Question;
            }
            else if (x.QuestionTypeId == 6 && y.QuestionId == this.radioSelected11) {
              qResponses.ResponseId = parseInt(y.Answers, 10);
              qResponses.ResponseValue = y.Question;
            }
            else if (qResponses.QuestionId > 129) {

              if (qResponses.QuestionId == 130) {
                qResponses.ResponseId = parseInt(y.Answers, 10);
              }
              else if (qResponses.QuestionId == 131) {
                qResponses.ResponseId = 7
              }
               else if (qResponses.QuestionId == 132) {
                qResponses.ResponseId = 8
              } 
              else if (qResponses.QuestionId == 133) {
                qResponses.ResponseId = 9
              }
              else if (qResponses.QuestionId == 134) {
                qResponses.ResponseId = 10
              }

              qResponses.ResponseValue = y.ResponseValue + "";
            }
            else {
              qResponses.ResponseValue = y.Answer + "";
              qResponses.ResponseId = null;
            }
            this.response.ListCandidateReferenceDetails.push(qResponses)
            //}
          });
        });
    });
    // debugger
    console.log("response", this.response)
    this.quizService.PostService(this.response)
    .subscribe(data => {
      //alert(data);
      this.relationStored()
      // this.toastr.successToastr('You have Completed Successfully','Success!');
      //this.submitRef = new InsertReferences();
      sessionStorage.removeItem('refId');
      this.others = undefined;
      this.OtherSelect = false;
      // if (this.loggedinUser != null) {
      //     this.router.navigateByUrl('dashboard');
      // } else {
      //     this.router.navigateByUrl('login');
      // }
  });



  }
  // isAnswered(index) {
  //   return this.quiz.questions[index].options.find(x => x.selected) ? 'Answered' : 'Not Answered';
  // };

  // isCorrect(question: Question) {
  //   return question.options.every(x => x.selected === x.isAnswer) ? 'correct' : 'wrong';
  // };

  // onSubmit() {
  //   let answers = [];
  //   this.quiz.questions.forEach(x => answers.push({ 'QuizId': this.quiz.id, 'QuestionId': x.id, 'Answered': x.answered }));

  //   // Post your data to the server here. answers contains the questionId and the users' answer.
  //   console.log(this.quiz.questions);
  //   this.mode = 'result';
  // }
}
export class SaveReferenceApproval {
  QuestionnaireId: number;
  uniqueCode: string;
  ListCandidateReferenceDetails: QuestionnaireResponses[] = [];
}
export class QuestionnaireResponses {
  ResponseId: number;
  QuestionId: number;
  ResponseValue: string;
  IsAnswered: string;
}


export class Profile {
  ProfileBasicInfo: Name;
}

export class Name {
  FirstName: string;
  LastName: string;
}
