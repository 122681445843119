export class Option {
    id: number;
    questionId: number;
    name: string;
    isAnswer: boolean;
    selected: boolean;

    constructor(data: any) {
        data = data || {};
        this.id = data.id;
        this.questionId = data.questionId;
        this.name = data.name;
        this.isAnswer = data.isAnswer;
    }
}

export class QuestionList {
    // id: number;
    // questionId: number;
    // name: string;
    // isAnswer: boolean;
    // selected: boolean;

    QuestionId :number;
    Question: string;
    Answers:string;
    Answer:string;
    ResponseValue:string;
    constructor(data: any) {
        data = data || {};
        // this.id = data.id;
        // this.questionId = data.questionId;
        // this.name = data.name;
        // this.isAnswer = data.isAnswer;
this.QuestionId=data.QuestionId;
this.Question= data.Question;
this.Answers=data.Answer;
    }
}
