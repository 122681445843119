import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators'
import { SettingsService } from 'src/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  constructor(private http: HttpClient, private settingsService: SettingsService) { }

  get(url: string) {
    return this.http.get(url).pipe(map(res => res));
  }

  getAll() {
    return [
      { id: 'data/aspnet.json', name: 'Asp.Net' },
      { id: 'data/csharp.json', name: 'C Sharp' },
      { id: 'data/designPatterns.json', name: 'Design Patterns' }
    ];
  }


GetProfile(Id)
{
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  headers.append('x-access-token', sessionStorage.getItem('token'));
  return this.http.get(this.settingsService.settings.ProfilebaseUrl+ "/api/GetUserProfileInfo?profileId=" + Id, { headers: headers })
      .pipe(map(res => res));
}


GetCandidateExperiences(Pid)
{
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  headers.append('x-access-token', sessionStorage.getItem('token'));
  return this.http.get(this.settingsService.settings.ProfilebaseUrl+ "/api/GetExperience?profileId=" + Pid +
  "&freeLance=0", { headers: headers })
      .pipe(map(res => res));
}

  GetService(prams)
  {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('x-access-token', sessionStorage.getItem('token'));
    return this.http.get(this.settingsService.settings.ProfilebaseUrl+ "/api/GetQuestionnaireQuestions?uniqueCode=" + prams, { headers: headers })
        .pipe(map(res => res));
  }

  PostService(body){

    return this.http.post(this.settingsService.settings.ProfilebaseUrl+"/api/SaveQuestionnaireResponses", body)
        .pipe(map(res => res));
  }

  postRelation(data){
    return this.http.post(this.settingsService.settings.adminbaseUrl+"/api/QuestionnaireQuestion", data)
  }

}

