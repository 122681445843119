import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { QuizComponent } from './quiz/quiz.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HighlightJsModule } from 'ngx-highlight-js';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AddQueComponent } from './add-que/add-que.component';
import { RouterModule, Routes } from '@angular/router';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas'; 
import {AuthGuard} from './auth.guard';
import { LoginComponent } from './login/login.component';
import {RatingModule} from "ngx-rating";
import { ToastrModule } from 'ng6-toastr-notifications';
import { SettingsHttpService } from 'src/settings/settings.http.service';
import { SettingsService } from 'src/settings/settings.service';


export function app_Init(settingsHttpService: SettingsHttpService) {
  return () => settingsHttpService.initializeApp();
}

const appRoutes: Routes = [
  
  {
    path: 'quiz',
    component: QuizComponent,
  },
  {
      path: 'quiz/:refId',
      component: QuizComponent,
    },
  // map '/' to '/persons' as our default route
  {
    path: '',
    redirectTo: '/quiz',
    pathMatch: 'full'
  },
  //{ path: 'login', component:  LoginComponent},
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    QuizComponent,
    AddQueComponent,
    PagenotfoundComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,BrowserAnimationsModule,
    FormsModule,RatingModule,
    HttpClientModule,HighlightJsModule,ToastrModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    )
    
  ],
  providers: [SettingsHttpService, SettingsService, HttpClient,
    { provide: APP_INITIALIZER, useFactory: app_Init, deps: [SettingsHttpService], multi: true },AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
